<script>
import ModalFormPayComponent from '@/components/contracts/ModaFormPayComponent.vue';
import ModalFilesBillComponent from '@/components/contracts/ModalFilesBillComponent.vue';
import request from '@/services/request'
import { mapActions } from 'vuex';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    components: {
        ModalFormPayComponent,
        ModalFilesBillComponent
    },
    data() {
        return {
            idAll: '',
            errors: {},
            tenant: {},
            totalAmount: 0,
            modify: false,
        }
    },
    methods: {
        closedModal() {
            this.pays = {};
            if (this.modify) {
                Event.$emit('event-load-debts');
            }
            this.modify = false;
            this.$modal.hide('ListPaysTenant');
        },
        formDataPays(tenant, edit = {}) {
            Event.$emit('event-show-addPays', tenant, edit);
        },
        async formDataPaysFast(pay, check = {}, edit = true) {
            let vm = this;
            vm.editPay = edit;
            Event.$emit('event-show-addPayContracts', pay, check, this.tenant.rent);
        },
        async uploadFileformDataPaysFast(pay) {
            Event.$emit('event-show-addUploadFiles', pay);
        },
        async list() {
            let service = request;
            let _endpoint = '/debt/' + this.idAll;
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    console.log(data);
                    this.tenant = data;
                    this.calculateTotalAmount();
                }
            } catch (error) {
                console.log(error);
            }
        },
        deleted: function (id) {
            let tit = 'Eliminar pago';
            let txt = 'Seguro que quiere eliminar la pago';

            this.$swal({
                title: tit,
                text: txt,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.callDeleted(id);
                }
            });
        },
        async callDeleted(id) {
            let _endpoint = '/tenant_pay/' + id;
            try {
                let response = await request.destroy(_endpoint).then(function (response) { return response; });
                this.list();
                this.modify = true;
                this.successNotification(response.data.message);
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        calculateTotalAmount() {
            // Calcular la suma de los montos de pagos
            this.totalAmount = this.tenant.checks.reduce((total, pay) => total + (pay.amount * 1), 0);
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    created() {
        Event.$on("event-show-PayContracts", (id) => {
            this.idAll = id;
            this.list();
            this.$modal.show('ListPaysTenant');
        });
        Event.$on("event-show-ReloadPayContracts", () => {
            this.modify = true;
            this.list();
        });
    }
}
</script>
<template>
    <modal name="ListPaysTenant" :width="'80%'" :scrollable="true" :maxHeight="300" :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Lista de pagos</h5>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="row">

                        <div class="col-md-12">
                            <button @click="formDataPaysFast(tenant, {}, false)"
                                v-if="prop_edit && totalAmount < this.tenant.amountN"
                                class="float-right btn btn-sm btn-outline-info">
                                <i class="fa fa-plus"></i> Pago
                            </button>
                        </div>
                    </div>
                    <h5 style=" float: left; margin-top: -30px;">Total a pagar: {{ tenant.amountN | toCurrency }}</h5>
                    <div class="clearfix"></div>
                    <hr>
                    <div class="table-responsive">
                        <table class="table table-bordered text-center text-sm">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Fecha de pago</th>
                                    <th>Item</th>
                                    <th>Descripción</th>
                                    <th>Tipo</th>
                                    <th>Monto</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody v-if="tenant.checks && tenant.checks.length > 0">
                                <tr v-for="(pay, index) in tenant.checks" :key="index">
                                    <td>{{ pay.id }}</td>
                                    <td>
                                        {{ pay.date | toDateTime }}
                                    </td>
                                    <td>
                                        <span v-if="pay.service_id == 0">
                                            Renta
                                        </span>
                                        <span v-else>
                                            <span v-if="pay.service">
                                                {{ pay.service.name }}
                                            </span>
                                            <span v-else-if="pay.service_renta">
                                                {{ pay.service_renta.name }}
                                            </span>
                                            <span v-else>
                                                <Samp></Samp>/N
                                            </span>
                                        </span>

                                    </td>
                                    <td>
                                        {{ pay.description }}
                                    </td>
                                    <td>
                                        <span v-if="pay.debt_type">
                                            {{ pay.debt_type.name }}
                                        </span>
                                        <span v-else>
                                            s/N
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {{ pay.amount | toCurrency }}
                                        </span>
                                    </td>
                                    <td>
                                        <a :href="`${globalUrlFiles}${pay.file}`" v-if="pay.file" target="_blank"
                                            style="border-radius: 5px 0px 5px 0px;"
                                            class="btn btn-outline-info m-1 btn-sm">
                                            <i class="fa-regular fa-file-pdf"></i> Archivo
                                        </a>
                                        <button v-if="prop_edit" @click="formDataPaysFast(tenant, pay, false)"
                                            class="btn btn-outline-warning m-1 btn-sm">
                                            <i class="fa-solid fa-hand-holding-dollar"> </i> Editar
                                        </button>
                                        <button v-if="prop_edit && $route.name != 'tax'" type="button"
                                            @click="deleted(pay.id)" class="btn-outline-danger btn m-1 btn-sm ">
                                            <i class="fa fa-trash"></i> Eliminar
                                        </button>
                                        <button v-if="prop_edit" @click="uploadFileformDataPaysFast(pay)"
                                            class="btn btn-outline-secondary m-1 btn-sm">
                                            <i class="fa-solid fa-file-invoice-dollar"> </i> Subir archivo
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="5" class="text-left"><strong>Total:</strong></td>
                                    <td>{{ totalAmount | toCurrency }}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colspan="5" class="text-left"><strong>Por pagar:</strong></td>
                                    <td>{{ tenant.amount - totalAmount | toCurrency }}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td align="center" colspan="7">Sin pagos.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <ModalFormPayComponent :prop_edit="prop_edit" />
        <ModalFilesBillComponent :prop_edit="prop_edit" />
    </modal>
</template>