<template>
	<div class="col-12">
		<div class="card card-outline card-navy">
			<div class="card-header">
				<h3 class="card-title">Lista de Pagos</h3>
				<div class="card-tools">
					<button @click="downloadFiles()" class="btn btn-sm btn-outline-secondary mr-1">
						<i class="fa-solid fa-box-archive"></i> Archivo de facturas
					</button>
					<button class="btn btn-outline-success btn-sm mr-2" @click="exportData()">
						<i class="fa fa-file-excel"></i> Exportar
					</button>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-7 col-lg-8">
						<label>Buscar :</label>
						<input class="form-control" v-debounce:1s="list" type="search" v-model="search.search">
					</div>
					<div class="col-md-5 col-lg-4">
						<label>Rango de fechas :</label>
						<date-picker format="YYYY-MM-DD" valueType="format" :range="true"
							v-model="search.date"></date-picker>
					</div>
					<div class="col-md-4 mt-2">
						<label>Empresa :</label>
						<select v-model="search.company_id" class="form-control">
							<option value="">Todos</option>
							<option v-for="(company, index) in catalogue.companies_buildings" :key="index"
								:value="company.id"> {{ company.name }}
							</option>
						</select>
					</div>
					<div class="col-md-4 mt-2">
						<label>Tipo :</label>
						<select type="text" class="form-control" v-model="search.type_id">
							<option value="">Todos</option>
							<option value="1">Presupuestos</option>
							<option value="2">Gasto recurrente</option>

						</select>
					</div>
					<div class="col-md-4 mt-2">
						<label>Usuario :</label>
						<select type="text" class="form-control" v-model="search.user_id">
							<option value="">Todos</option>
							<option v-for="(user, index) in catalogue.users_profile" :value="user.id" :key="index">
								{{ user.name }} {{ user.last_name }}</option>
						</select>
					</div>
					<div class="col-md-4 mt-2">
						<label>Categoría :</label>
						<select type="text" class="form-control" v-model="search.concept_id">
							<option value="">Todos</option>
							<option v-for="(concept, index) in catalogue.concepts" :value="concept.id" :key="index">
								{{ concept.name }}</option>
						</select>
					</div>
					<div class="col-md-4 mt-2">
						<label>Tipo de saldo</label>
						<select type="text" class="form-control" v-model="search.type_payment_id">
							<option value="">Todos</option>
							<option v-for="(type_payment, index_type_payment) in catalogue.type_payments"
								:value="type_payment.id" :key="index_type_payment">{{
						type_payment.name }}</option>
						</select>
					</div>
					<div class="col-md-4 mt-2">
						<label>Tipo de factura :</label>
						<select class="form-control" v-model="search.biill_type_id">
							<option value="">Todos</option>
							<option v-for="(type, index) in catalogue.bills_types" :value="type.id" :key="index">{{
						type.name }}</option>
						</select>
					</div>
					<div class="col-md-4 mt-2">
						<label>Razón Social :</label>
						<select class="form-control" v-model="search.billing_data_id">
							<option value="">Todos</option>
							<option v-for="(type, index) in catalogue.all_billing_data" :value="type.id" :key="index">
								{{ type.business_name }} - {{ type.rfc }}
							</option>
						</select>
					</div>
				</div>
				<br>
				<div class="table-responsive">
					<table class="table table-bordered text-sm text-center">
						<thead>
							<tr>
								<th>ID</th>
								<th style="width: 45%;">Datos</th>
								<th style="width: 140px;">Monto</th>
								<th style="width: 140px;">Por pagar</th>
								<th style="width: 140px;">Pagado</th>
								<th>Fecha de aprobación</th>
								<th>Fecha de creación</th>
								<th>Fecha de factura</th>
								<th class="text-center">Opciones</th>
								<th v-can="'add_pays_fast'" v-if="paymentConditions()" class="text-center">
									<button class="btn btn-sm btn-info" @click="selectAll">
										<i class="fa-solid fa-check-double"></i>
									</button>
								</th>
							</tr>
						</thead>
						<tbody v-if="load">
							<tr>
								<td align="center" colspan="10">
									<i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
									<h5>Cargando</h5>

								</td>
							</tr>
						</tbody>
						<tbody v-else-if="pays.total > 0">
							<tr v-for="( buy, index ) in  pays.data " :key="index">
								<td>{{ buy.id }}</td>
								<td class="text-left">
									<!-- Tipo -->
									<span v-if="buy.type_id == 1"> Presupuesto </span>
									<span v-else> Gasto recurrente </span>
									<br>

									<hr>

									<strong>Datos de facturación </strong>
									<br>

									<!-- Razón social -->
									<span v-if="buy.bill">
										<span v-if="buy.bill.user_bill">
											{{ buy.bill.user_bill.business_name }}
										</span>
										<span v-else>Sin Razón social</span>
									</span>
									<span v-else>Sin Razón social</span>
									<br>

									<!-- Folio Factura -->
									<strong>Folio : </strong>
									<span v-if="buy.bill">
										<span v-if="buy.bill.folio && buy.bill.folio != 'null'">
											{{ buy.bill.folio }}
										</span>
										<span v-else>Sin Folio</span>
									</span>
									<span v-else>Sin Folio</span>
									<br>



									<!-- RFC -->
									<span v-if="buy.bill">
										<span v-if="buy.bill.user_bill">
											{{ buy.bill.user_bill.rfc }} |
											<a class="btn btn-info btn-sm" target="_blank"
												v-if="buy.bill.user_bill.cedula"
												:href="`${globalUrlFiles}${buy.bill.user_bill.cedula}`">
												<i class="fa-regular fa-file-pdf"></i> Ver cédula fiscal
											</a>
											<small v-else class="btn_now badge badge-secondary"> Sin caratula
												fiscal</small>

										</span>
										<span v-else>Sin RFC</span>
									</span>
									<span v-else>Sin RFC</span>
									<br>

									<strong>Aprobado por :</strong><br>
									<span v-if="buy.bill">
										<span v-if="buy.bill.provider">
											{{ buy.bill.provider.name }} {{ buy.bill.provider.last_name }}
										</span>
										<span v-else>S/D</span>
									</span>
									<span v-else>S/D</span>
									<br>
									<hr>


									<!-- Proveedor -->
									<strong>Usuario : </strong>
									<span v-if="buy.provider"> {{ buy.provider.name }} {{ buy.provider.last_name
										}}</span>
									<span v-else>Sin usuario</span>
									<br>

									<!-- Concepto -->
									<strong>Concepto : </strong>
									<span v-if="buy.bill"> {{ buy.bill.name }}</span>
									<span v-else>Sin Concepto</span>
									<br>

									<!-- Empresa -->
									<strong>Empresa: </strong>
									<span v-if="buy.bill">
										<span v-if="buy.bill.company">
											{{ buy.bill.company.name }}
										</span>
									</span>
									<span v-else>Sin Empresa</span>
									<br>
									<br>

									<!-- Tipo -->
									<strong>Tipo de movimiento : </strong>
									<span v-if="buy.type_payment"> {{ buy.type_payment.name }}</span>
									<span v-else>Sin tipo</span>
									<br>

									<!-- Categoría -->
									<strong>Categoría : </strong>
									<span v-if="buy.concept"> {{ buy.concept.name }}</span>
									<span v-else>Sin categoría</span>

									<hr>
									<strong>Cuenta Bancaria </strong>
									<br>

									<!-- Banco -->
									<span v-if="buy.bill">
										<span v-if="buy.bill.bank_account_providers">
											{{ buy.bill.bank_account_providers.bank.name }}
										</span>
										<span v-else>Sin Banco</span>
									</span>
									<span v-else>Sin Banco</span>
									<br>

									<!-- Cuenta -->
									<span v-if="buy.bill">
										<span v-if="buy.bill.bank_account_providers">
											{{ buy.bill.bank_account_providers.clue_number }} |
											<a class="btn btn-info btn-sm" target="_blank"
												v-if="buy.bill.bank_account_providers.file"
												:href="`${globalUrlFiles}${buy.bill.bank_account_providers.file}`">
												<i class="fa-regular fa-file-pdf"></i> Ver caratula de banco
											</a>
											<small v-else class="btn_now badge badge-secondary"> Sin caratula de
												banco</small>
										</span>
										<span v-else>Sin Cuenta</span>
									</span>
									<span v-else>Sin Cuenta</span>
									<br>

									<!-- Concepto -->
									<strong>Concepto : </strong>
									<span v-if="buy.bill"> {{ buy.bill.name }}</span>
									<span v-else>Sin Concepto</span>
									<br>

									<!-- Moneda -->
									<strong>Moneda : </strong>
									<span v-if="buy.bill"> {{ buy.bill.currency.name }}</span>
									<span v-else>S/D</span>
									<br>

									<!-- Descripción -->
									<strong>Descripción : </strong>
									<span v-if="buy.bill"> {{ buy.bill.description }}</span>
									<span v-else>Sin Descripción</span>
									<br>

									<!-- Mensaje de aceptación -->
									<hr v-if="buy.authorization_message">
									<strong v-if="buy.authorization_message">Comentarios de aprobación : </strong>
									<br>
									<span v-if="buy.authorization_message"> {{ buy.authorization_message }}</span>
									<hr v-if="buy.authorization_message">



								</td>
								<td class="text-right">
									{{ (buy.amount + buy.credit) | toCurrency }}
								</td>
								<td class="text-right">
									<strong style="font-weight: 900;">{{ buy.amount_payable | toCurrency }}</strong>
								</td>
								<td class="text-right">
									{{ buy.sum_pays | toCurrency }}
								</td>
								<td>
									<span v-if="buy.user_approved"> {{ buy.authorization_date | toDateTime }} </span>
									<span v-else>Sin Fecha</span>
								</td>
								<td>
									{{ buy.created_at | toDateTime }}
								</td>
								<td>
									<span v-if="buy.bill">
										{{ buy.bill.date | toDateTime }}
									</span>
									<span v-else>
										S/F
									</span>

								</td>
								<!-- <td>
									<span v-if="buy.pay"> {{ buy.pay.user_approved.name }} {{
										buy.pay.user_approved.last_name }}</span>
									<span v-else>Sin Usuario</span>
								</td>
								<td>
									<span v-if="buy.pay"> {{ buy.pay.authorization_date | toDateTime }} </span>
									<span v-else>Sin Fecha</span>
								</td> -->
								<td class="text-center">
									<button v-if="buy.bill" @click="formDataBill(buy.bill)"
										class="btn btn-outline-secondary m-1 btn-sm">
										<i class="fa-solid fa-receipt mr-1"></i> Ver cheque
									</button>
									<button @click="cancelPays(buy.id)" v-can="'deleted_pays'"
										class="btn btn-outline-danger m-1 btn-sm" v-if="buy.pay.length == 0">
										<i class="fa-solid fa-xmark"></i> Cancelar aprobación
									</button>


									<button v-if="buy.type_id == 1" @click="showBudget(buy.budget.id)" type="button"
										class="btn btn-sm  m-1 btn-outline-light ">
										<i class="fa-solid fa-coins mr-1"></i>Ver presupuesto
									</button>

									<button v-if="buy.type_id == 2 && buy.buy.payment_period_id !== 5"
										@click="viewPay(buy.buy_id)" type="button"
										class="btn btn-sm  m-1 btn-outline-light ">
										<i class="fa-solid fa-basket-shopping mr-1"></i>Ver compra recurrente
									</button>

									<button v-if="buy.type_id == 2 && buy.buy.buy_id" @click="viewPay(buy.buy.buy_id)"
										type="button" class="btn btn-sm  m-1 btn-outline-light ">
										<i class="fa-solid fa-basket-shopping mr-1"></i>Ver compra recurrente
									</button>



									<!-- <button @click="formData(buy)" v-can="'edit_pays'"
										class="btn btn-outline-primary m-1 btn-sm">
										<i class="fas fa-edit "> </i> Editar
									</button> -->


									<!-- <button type="button" v-if="buy.pay.length == 0" @click="deleted(buy.id)" v-can="'deleted_checks'"
										class="btn-outline-danger btn m-1 btn-sm ">
										<i class="fa fa-trash"></i> Eliminar
									</button> -->
									<!-- <button @click="formDataPays(buy, buy.pay)" v-can="'add_pays'" class="btn m-1 btn-sm btn-outline-warning">
										<i class="fa-solid fa-hand-holding-dollar mr-1"> </i>
										<span>Ver pago</span>
									</button> -->
									<button @click="formListPays(buy)" class="btn m-1 btn-sm btn-outline-warning">
										<i class="fa-solid fa-hand-holding-dollar mr-1"> </i>
										<span>Ver pago</span>
									</button>
									<button @click="formDataPaysFast(buy)" v-can="'add_pays_fast'"
										class="btn btn-outline-info m-1 btn-sm" v-if="buy.pay.length == 0 && buy.bill">
										<i class="fa-regular fa-handshake mr-1"></i>
										<span>Pago rápido</span>
									</button>

									<div class="d-inline" v-if="buy.bill">
										<button type="button" v-if="buy.type_payment_id == 5 && buy.signature"
											@click="downloadRecibe(buy.id)"
											class="btn m-1 btn-outline-info btn btn-sm ">
											<i class="fa-solid fa-file-pdf"></i> Recibo de pago
										</button>
										<button type="button" v-can="'approve_pays'"
											v-if="buy.type_payment_id == 5 && !buy.signature"
											@click="addSignature(buy)"
											class="btn m-1 btn-outline-secondary btn btn-sm ">
											<i class="fa-solid fa-signature"></i> Firmar pago
										</button>

										<a v-if="buy.bill.file" :href="`${globalUrlFiles}${buy.bill.file}`"
											target="_blank" class="btn m-1 btn-outline-secondary btn-sm">
											<i class="fas fa-file-pdf "> </i> Archivo PDF
										</a>
										<button v-if="buy.bill.xml" class="btn m-1 btn-outline-secondary btn-sm"
											@click="downloadFile('xml', buy.bill.id)">
											<i class="fas fa-file "> </i> Archivo XML
										</button>

										<button type="button" v-if="buy.bill" @click="showChecks(buy.bill)"
											class="btn m-1 btn-outline-success btn btn-sm ">
											<i class="fa-solid fa-receipt"></i> Ver cheques de la factura
										</button>

										<div class="d-inline" v-if="buy.bill">
											<button type="button" v-if="buy.bill.pety_cash" @click="showPettyCash(buy.bill.pety_cash)"
												class="btn m-1 btn-outline-success btn btn-sm ">
												<i class="fa-solid fa-cash-register"></i> Ver movimiento
											</button>
										</div>
									</div>

									<div class="d-inline" v-if="buy.bill">
										<button @click="newCredit(buy.bill, buy.bill.credit)" v-if="buy.bill.credit"
											class="btn m-1 btn-sm btn-outline-info">
											<i class="fa-solid fa-comment-dollar mr-1"></i>Ver nota de crédito
										</button>
									</div>


								</td>
								<th class="text-center" v-can="'add_pays_fast'" v-if="paymentConditions()">
									<input type="checkbox" class="form-control form-control-sm" :value="buy.id"
										v-model="Arrauthorized">
								</th>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="10">Sin resultados.</td>
							</tr>
						</tbody>
						<tfoot v-if="pays.total > 0 && load == false">
							<tr class="table-secondary">
								<td colspan="2">
									Total :
								</td>
								<td class="text-right">
									{{ sumdata()[0] | toCurrency }}
								</td>
								<td class="text-right">
									{{ sumdata()[1] | toCurrency }}
								</td>
								<td class="text-right">
									{{ sumdata()[2] | toCurrency }}
								</td>
								<td colspan="4"></td>
							</tr>
						</tfoot>
					</table>
					<pagination v-if="pays" v-model="page" :records="pays.total" :per-page="pays.per_page"
						@paginate="list" />
				</div>
				<div class="row mt-3" v-can="'add_pays_fast'" v-if="paymentConditions()">
					<div class="col-12 text-right">
						<button class="btn btn-outline-info" v-if="!sendGroup" @click="formDataPaysFastGroup()">
							<i class="fa-solid fa-list-check"></i> Pago rápido
							seleccionados
						</button>
						<button class="btn btn-outline-info" v-else>
							<i class="fa-solid fa-circle-notch fa-spin"></i> Pagando
						</button>
					</div>
				</div>

			</div>
		</div>

		<ModalFormComponent :prop_edit="false" />
		<ModalTableComponentPays :prop_edit="false" />
		<ModalFormComponentBill :prop_edit="false" />
		<ModalDownloadFiles />
		<ModalSignatureComponentVue />
		<ModalBuysTable />
		<ModalBudgetsTable />
		<ModalBuysRelationComponent />
		<ModalCredit :prop_edit="false" />
		<LoadPay />
		<ModalChecksBillSummary />
		<ModalFormComponentBudgetsPetty :prop_edit="false" />

	</div>
</template>



<script>
import ModalCredit from '@/components/bill/ModalCreditComponent.vue';
import ModalDownloadFiles from '@/components/bill/ModalDownloadFilesComponent.vue';
import ModalFormComponent from '@/components/checks/ModalFormComponent.vue';
import ModalTableComponentPays from '@/components/pays/ModalTableComponent.vue';
import ModalFormComponentBill from '@/components/bill/ModalFormComponent.vue';
import ModalSignatureComponentVue from '@/components/pays/ModalSignatureComponent.vue';
import ModalBuysTable from '@/components/buy/ModalTableComponent.vue'
import ModalBudgetsTable from '@/components/budgets/ModalTableComponent.vue'
import ModalBuysRelationComponent from '@/components/buy/ModalBuysRelationComponent.vue';
import ModalChecksBillSummary from '@/components/checks/ModalChecksBillSummaryComponent.vue'
import ModalFormComponentBudgetsPetty from '@/components/petty_cash/ModalFormPettyCashComponent.vue'

import LoadPay from '@/components/LoadPayPageComponent.vue';

import { mapMutations, mapActions, mapGetters } from 'vuex';
import Pagination from 'vue-pagination-2';
import request from '@/services/request';
import Catalogue from '@/services/catalogue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
	name: "companies-table",
	components: {
		ModalFormComponent,
		ModalTableComponentPays,
		ModalFormComponentBill,
		ModalDownloadFiles,
		ModalSignatureComponentVue,
		Pagination,
		DatePicker,
		ModalBuysTable,
		ModalBudgetsTable,
		ModalBuysRelationComponent,
		ModalCredit,
		LoadPay,
		ModalChecksBillSummary,
		ModalFormComponentBudgetsPetty
	},
	data() {
		return {
			Arrauthorized: [],
			sendGroup: false,
			pays: {},
			name: '',
			search: {
				date: '',
				company_id: '',
				paid: '',
				search: '',
				type_id: '',
				user_id: '',
				concept_id: '',
				biill_type_id: '',
				type_payment_id: '',
				billing_data_id: '',
				complement: ''
			},
			page: 1,
			catalogue: new Catalogue(),
			load: false,
		}
	},
	mounted() {
		this.catalogue.get_companies_buildings();
		this.catalogue.get_users_profile(2);
		this.catalogue.get_concepts();
		this.catalogue.get_type_payments();
		this.catalogue.get_bills_types();
		this.catalogue.get_all_billing_data();
		this.list();
		this.setHeaderTitle('Gasto recurrente');
	},
	created() {
		Event.$on("event-load-checks", () => {
			this.list();
		});
	},
	methods: {
		showPettyCash(petty = false) {
			Event.$emit('event-show-addPettyCash', petty);
		},
		showChecks(bill = {}) {
			Event.$emit('event-show-listChecksBillsSummary', bill);
		},
		newCredit(bill, credit) {
			Event.$emit('event-show-addBillCredit', bill, credit);
		},
		downloadFiles() {
			let filter = this.search;
			filter.type = 'pays';
			Event.$emit('event-show-downloadFiles', filter);
		},
		sumdata() {
			let sum1 = 0;
			let sum2 = 0;
			let sum3 = 0;
			if (this.pays) {
				this.pays.data.forEach(function (buy) {
					console.log(buy.amount + buy.credit);
					if (buy.amount) {
						sum1 += parseFloat(buy.amount);
					}
					if (buy.credit) {
						sum1 += parseFloat(buy.credit);
					}
					sum2 += parseFloat(buy.amount_payable);
					sum3 += parseFloat(buy.sum_pays);

				});
			}

			return [sum1, sum2, sum3];
		},
		viewPay(id) {
			// Event.$emit('event-load-buy', id);
			Event.$emit('event-show-BuysRelationID', id);
		},
		selectAll() {
			this.Arrauthorized = [];
			let selected = this.Arrauthorized;
			this.pays.data.forEach(element => {
				if (!selected.includes(element.id)) {
					selected.push(element.id);
				}
			});
			this.Arrauthorized = selected;
		},
		downloadFile(type, id) {
			let url = this.globalUrlFiles + '/bills/download_file?id=' + id + '&type=' + type;
			window.open(url, '_blank');
		},
		downloadRecibe(id) {
			window.open(this.globalUrlFiles + '/bills/pdf/' + id, '_blank');
		},
		exportData() {
			let filter = this.globalUrlFiles + '/pays/export?' + new URLSearchParams(this.search).toString();
			filter += '&user_login=' + this.authUser.id;
			window.open(filter, '_blank');
		},
		cancelPays(id) {
			let vm = this;
			console.log(id);
			this.$swal({
				title: 'Motivos de Cancelación',
				input: 'text',
				icon: 'warning',
				inputAttributes: {
					autocapitalize: 'off'
				},
				showCancelButton: true,
				confirmButtonText: 'Guardar',
				showLoaderOnConfirm: true,
				preConfirm: async (message) => {
					console.log(message);
					let form = {
						id: id,
						message: message,
					};
					let _endpoint = '/cancel_pays';
					try {
						let { data } = await request.postMedia(_endpoint, form)
						if (data) {
							console.log(data);
							await vm.successNotification(data.message);
							await vm.list();
						}
					} catch (error) {
						vm.errors = {};
						if (error.response) {
							vm.errors = vm.errorNotification(error);
						}
					}
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {
					console.log(result);
				}
			})

		},
		paymentConditions() {
			return this.search.date == '' && this.search.company_id != '' &&
				this.search.paid == '' &&
				this.search.search == '' &&
				this.search.type_id == '' &&
				this.search.user_id == '' &&
				this.search.concept_id == '' &&
				this.search.biill_type_id == '' &&
				this.search.type_payment_id == '' &&
				this.search.complement == ''
		},
		addSignature(bill = {}) {
			console.log(bill);
			Event.$emit('event-show-addSiganturePay', bill);
		},
		formData(edit = {}) {
			console.log(edit);
			Event.$emit('event-show-addChecks', edit);
		},
		formDataBill(edit = {}) {
			console.log(edit);
			Event.$emit('event-show-addBill', edit);
		},
		formDataPays(check, edit = {}) {
			Event.$emit('event-show-addPays', check, edit);
		},
		showBudget(id) {
			Event.$emit('event-load-budget', id);
		},
		formListPays(check) {
			Event.$emit('event-show-listPays', check);
		},
		async formDataPaysFast(check) {
			if (check.bill) {
				if (check.bill.biill_type_id == 3) {
					this.payAction(check);
					return false;
				}
			}

			let company_id = check.bill.company_id;
			await this.catalogue.getBankAccountCompany(company_id);
			let bancks = this.catalogue.bank_account_company;
			let banco = [];
			bancks.forEach(function (bank) {
				let name = '';
				if (bank.bank) {
					name += bank.bank.name + ' - ';
				}
				name += bank.number;
				banco['_' + bank.id] = name;
			});

			if (bancks.length == 0) {
				this.$swal({
					title: "No se cuenta con cuenta",
					text: "No se puede pagar es requerida una cuenta",
					icon: "info"
				});
			}

			if (bancks.length > 1) {
				const { value: bank_id } = await this.$swal({
					title: "Cuenta de banco",
					html: '<div class="col-12"><input type="date" id="input_date_11" class="form-control"></div>',
					input: "select",
					inputOptions: banco,
					inputPlaceholder: "Seleccione una cuenta de banco",
					showCancelButton: true,
					inputValidator: (value) => {
						console.log(value);
						return new Promise((resolve) => {
							let date = document.getElementById('input_date_11').value;
							console.log(date);

							if (value !== "" && date !== "") {
								resolve();
							} else {
								if (value == "") {
									resolve("Es requerida la cuenta de banco");
								} else {
									resolve("Es requerida la fecha");
								}
							}
						});
					}
				});

				if (bank_id) {
					this.$modal.show('LoadPay');

					let form = {
						check_id: check.id,
						bank_id: bank_id.substring(1),
						date: document.getElementById('input_date_11').value,
						amount: check.amount_payable ? check.amount_payable : check.amount,
						description: 'Pago aprobado'
					};
					let _endpoint = '/pays';
					try {
						let { data } = await request.postMedia(_endpoint, form)
						if (data) {
							this.$modal.hide('LoadPay');
							console.log(data);
							this.successNotification(data.message);
							this.list();
							Event.$emit('event-load-pays');

						}
					} catch (error) {
						this.$modal.hide('LoadPay');
						this.errors = {};
						if (error.response) {
							this.errors = await this.errorNotification(error);
						}
					}
				}
			} else {

				console.log(bancks[0].id);

				this.$swal({
					title: 'Pago rápido',
					text: 'Se aprobara el pago rápido',
					html: '<input type="date" id="input_date_1" class="form-control">',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Pagar',
					cancelButtonText: 'Cancelar'
				}).then(async (result) => {
					if (result.isConfirmed) {
						this.$modal.show('LoadPay');

						let form = {
							check_id: check.id,
							bank_id: bancks[0].id,
							date: document.getElementById('input_date_1').value,
							amount: check.amount_payable ? check.amount_payable : check.amount,
							description: 'Pago aprobado'
						};
						let _endpoint = '/pays';
						try {
							let { data } = await request.postMedia(_endpoint, form)
							if (data) {
								this.$modal.hide('LoadPay');

								console.log(data);
								this.successNotification(data.message);

								this.list();
								Event.$emit('event-load-pays');


							}
						} catch (error) {
							this.$modal.hide('LoadPay');

							this.errors = {};
							if (error.response) {
								this.errors = await this.errorNotification(error);
							}
						}
					}
				});
			}
		},
		async payAction(check, bancks_id = '') {
			this.$swal({
				title: 'Pago rápido',
				text: 'Se aprobara el pago rápido',
				html: '<input type="date" id="input_date_3" class="form-control">',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Pagar',
				cancelButtonText: 'Cancelar'
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.$modal.show('LoadPay');

					let form = {
						check_id: check.id,
						bank_id: bancks_id,
						date: document.getElementById('input_date_3').value,
						amount: check.amount_payable,
						description: 'Pago aprobado'
					};
					let _endpoint = '/pays';
					try {
						let { data } = await request.postMedia(_endpoint, form)
						if (data) {
							this.$modal.hide('LoadPay');

							console.log(data);
							this.successNotification(data.message);
							this.list();
							Event.$emit('event-load-pays');

						}
					} catch (error) {
						this.$modal.hide('LoadPay');

						this.errors = {};
						if (error.response) {
							this.errors = await this.errorNotification(error);
						}
					}
				}
			});
		},
		async formDataPaysFastGroup() {
			let company_id = this.search.company_id;
			let Arrauthorized = this.Arrauthorized;
			await this.catalogue.getBankAccountCompany(company_id);
			let bancks = this.catalogue.bank_account_company;
			let banco = [];
			bancks.forEach(function (bank) {
				let name = '';
				if (bank.bank) {
					name += bank.bank.name + ' - ';
				}
				name += bank.number;
				banco['_' + bank.id] = name;
			});

			if (bancks.length == 0) {
				this.$swal({
					title: "No se cuenta con cuenta",
					text: "No se puede pagar es requerida una cuenta",
					icon: "info"
				});
			}

			if (bancks.length > 1) {
				const { value: bank_id } = await this.$swal({
					title: "Cuenta de banco",
					html: '<div class="col-12"><input type="date" id="input_date_12" class="form-control"></div>',
					input: "select",
					inputOptions: banco,
					inputPlaceholder: "Seleccione una cuenta de banco",
					showCancelButton: true,
					inputValidator: (value) => {
						console.log(value);
						return new Promise((resolve) => {
							let date = document.getElementById('input_date_12').value;
							if (value !== "" && date !== "") {
								resolve();
							} else {
								if (value == "") {
									resolve("Es requerida la cuenta de banco");
								} else {
									resolve("Es requerida la fecha");
								}
							}
						});
					}
				});

				if (bank_id) {
					this.sendGroup = true;
					let form = {
						checks_id: Arrauthorized,
						bank_id: bank_id.substring(1),
						date: document.getElementById('input_date_12').value,
						description: 'Pago aprobado'
					};
					let _endpoint = '/pays_group';
					try {
						let { data } = await request.postMedia(_endpoint, form)
						if (data) {
							console.log(data);
							this.successNotification(data.message);
							this.list();
							Event.$emit('event-load-pays');
							this.sendGroup = false;

						}
					} catch (error) {
						this.errors = {};
						if (error.response) {
							this.errors = await this.errorNotification(error);
							this.sendGroup = false;
						}
					}
				}
			} else {

				console.log(bancks[0].id);

				this.$swal({
					title: 'Pago rápido',
					text: 'Se aprobara el pago rápido',
					html: '<input type="date" id="input_date_2" class="form-control">',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Pagar',
					cancelButtonText: 'Cancelar'
				}).then(async (result) => {
					if (result.isConfirmed) {
						this.sendGroup = true;
						let form = {
							checks_id: Arrauthorized,
							bank_id: bancks[0].id,
							date: document.getElementById('input_date_2').value,
							description: 'Pago aprobado'
						};
						let _endpoint = '/pays_group';
						try {
							let { data } = await request.postMedia(_endpoint, form)
							if (data) {
								console.log(data);
								this.successNotification(data.message);
								this.list();
								Event.$emit('event-load-pays');
								this.sendGroup = false;
							}
						} catch (error) {
							this.errors = {};
							if (error.response) {
								this.errors = await this.errorNotification(error);
								this.sendGroup = false;

							}
						}
					}
				});
			}
		},
		deleted: function (id) {
			let tit = 'Eliminar cheque';
			let txt = 'Seguro que quiere eliminar la cheque';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		approve: function (id) {
			let tit = 'Aprobar Presupuestos';
			let txt = 'Seguro que quiere aprobar el presupuestos';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then(async (result) => {
				if (result.isConfirmed) {
					let _endpoint = '/pays_approve';
					let form = {
						id: id
					};
					try {
						let response = await request.post(_endpoint, form).then(function (response) { return response; });
						this.list();
						Event.$emit('event-load-pays');
						this.successNotification(response.data.message);
					} catch (error) {
						this.errors = await this.errorNotification(error)
					}
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/pays/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				Event.$emit('event-load-pays');
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport() {
			this.$modal.show('example');
		},
		async list(page = this.page) {
			this.load = true;
			let service = request;
			let search = this.search;
			let _endpoint = '/pays';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.pays = data;
					this.load = false;
					this.Arrauthorized = [];
				}
			} catch (error) {
				console.log(error);
				this.load = false;
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
	watch: {
		'name': {
			handler: function (val) {
				if (val == '') {
					this.list();
				}
			},
			deep: true
		},
		"search": {
			handler: function () {
				this.page = 1;
				this.list();
			},
			deep: true
		}
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}

.bg-info {
	background-color: #061f3f;
}

.pay_load {
	z-index: 5;
	position: absolute;
	width: 98%;
	height: 100%;
	border-radius: 10px;
	background: #204d83cc;
}

.load_pay {

	position: fixed;
	display: block;
	top: 20%;
	transform: translateY(-20%);
	left: 50%;
	transform: translateX(-50%);
}
</style>
