<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    components: {
        DatePicker
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                bank_id: '',
                amount: '',
                amount_payable: '',
                date: '',
                file: '',
                tenant_id: '',
                service_id: '',
                serv_id: '',
                debts_type_id: '',
                service_type:''
            },
            cataloguePays: [],
            servicesExtras:[],
            checks: [],
            rent: '',
            file: '',
            file_name: '',
            send: false,
            load: false,
        }
    },
    methods: {
        dataPay(id = 0) {
            let sumPay = 0;
            if (id == 0) {
                sumPay = this.rent;
                console.log(this.servicesExtras);
                let paysGrupo = this.servicesExtras.filter(item => item.pivot.group_bill == 1);
                if (paysGrupo) {
                    let totalAmount = paysGrupo.reduce((sum, item) => sum + (item.pivot.amount * 1), 0);
                    sumPay = (sumPay * 1) + (totalAmount * 1);
                }
                console.log(paysGrupo); 
                this.form.serv_id = 0;
                this.form.service_type = 1;
            } else {
                let arr = id.split('-'); 
                console.log(arr);
                this.form.serv_id = arr[0];
                this.form.service_type = arr[1];
                if(arr[1] == 1){
                    const objetoEspecifico = this.cataloguePays.find(item => item.id == arr[0]);
                    sumPay = objetoEspecifico.amount;
                }else{
                    const objetoEspecifico = this.servicesExtras.find(item => item.id == arr[0]);
                    console.log(objetoEspecifico);
                    sumPay = objetoEspecifico.pivot.amount;
                }
            }

            let totalAmount = 0;
            let pays = {};
            if (id == 0) {
                pays = this.checks.filter(item => item.service_id == id); 
            }else{                
                let arr = id.split('-'); 
                console.log(this.checks);
                console.log(arr[0]);
                console.log(arr[1]);
                pays = this.checks.filter(item => item.service_id == arr[0] && item.service_type == arr[1]); 
            }
            if (pays) {
                console.log(pays);
                totalAmount = pays.reduce((sum, item) => sum + (item.amount * 1), 0);
            }
            console.log(sumPay);
            console.log(totalAmount);

            this.form.amount = (sumPay * 1) - (totalAmount * 1);
            this.form.amount_payable = (sumPay * 1) - (totalAmount * 1);
        },
        closedModal() {
            this.form.id = '';
            this.form.bank_id = '';
            this.form.file = '';
            this.form.date = '';
            this.form.tenant_id = '';
            this.form.amount = '';
            this.form.serv_id = '';
            this.form.amount_payable = '';
            this.form.description = '';
            this.form.service_id = '';
            this.form.service_type = '';
            this.file = '';
            this.file_name = '';
            this.send = false;
            this.load = false;
            this.form.debts_type_id = '';
            this.$modal.hide('addPayContract');

        },
        catch_file(event) {
            const validation = [
                "application/pdf",
                "image/gif",
                "image/jpeg",
                "image/jpg",
                "image/png",
            ];
            if (validation.includes(event.target.files[0].type)) {
                this.form.file = event.target.files[0];
                this.file_name = event.target.files[0].name;
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos PDF',
                });
            }
        },

        async savePayTaxes() {
            const data_form = new FormData();
            data_form.append('id', this.form.id)
            data_form.append('tenant_id', this.form.tenant_id)
            data_form.append('bank_id', this.form.bank_id)
            data_form.append('date', this.form.date)
            data_form.append('amount', this.form.amount)
            data_form.append('amount_payable', this.form.amount_payable)
            data_form.append('description', this.form.description)
            data_form.append('require', this.form.require)
            // data_form.append('service_id', this.form.service_id)
            data_form.append('service_id', this.form.serv_id)
            data_form.append('file', this.form.file)
            data_form.append('debts_type_id', this.form.debts_type_id)
            data_form.append('service_type', this.form.service_type)


            
            let _endpoint = '/tenant_pay';
            if (this.form.id != "") {
                // data_form.append('_method', 'PUT');
                _endpoint = `/tenant_pay/${this.form.id}`;
            }

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-show-ReloadPayContracts');
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }

        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    async mounted() {
        this.catalogue.get_types_bill_number();
    },
    created() {
        Event.$on("event-show-addPayContracts", async (tax, check, rent) => {

            console.log('tax')
            console.log(tax)
            console.log(check)

            this.rent = rent;
            if (tax.id) {
                this.form.tenant_id = tax.id;
                this.form.bank_id = tax.bank_account_id;
                this.form.amount = 0;
                this.form.amount_payable = 0;
                this.form.description = tax.description;
                this.cataloguePays = tax.services_extras_unavailable;
                this.servicesExtras = tax.services_extras;
                this.checks = tax.checks;
            }

            if (check) {
                if (check.id) {
                    this.form.id = check.id;
                    this.form.date = check.date;
                    this.form.amount = check.amount_payable;
                    this.form.amount_payable = check.amount_payable;
                    this.form.description = check.description;
                    this.form.service_id = check.service_id;
                    this.form.debts_type_id = check.debts_type_id;
                    if (check.file) {
                        this.file = check.file;
                    }
                }
            }
            this.$modal.show('addPayContract');

        })
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
    watch: {
        'form.service_id'(nVal) {
            console.log(nVal);
            if (nVal != '') {
                this.dataPay(nVal);
            }
        },
        deep: true
    },
}
</script>
<template>
    <modal name="addPayContract" :width="'80%'" :maxHeight="300" :height="'auto'" v-bind:click-to-close="false">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="savePayTaxes" method="post" id="formTax" enctype="multipart/form-data">
            <button :disabled="send" type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                    class="fa-solid fa-xmark"></i> </button>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Información de pago</h5>
            </div>
            <div class="modal-body">
                <div class="row">

                    <div class="col-md-6 mt-3">
                        <label>Item</label>                      
                        <select class="form-control" v-model="form.service_id" :disabled="!prop_edit"
                            required="required">
                            <option value="">Selecciona un item</option>
                            <option value="0">Renta</option>
                            <option :value="`${item.id}-1`" v-for="(item, index) in cataloguePays" :key="index">{{ item.name }}
                            </option>
                            <option v-show="item.pivot.group_bill == 0" :value="`${item.id}-2`" v-for="(item, index) in servicesExtras" :key="index">{{ item.name }}
                            </option>
                            
                        </select>
                        <div v-if="errors && errors.service_id" class="text-danger">{{ errors.service_id[0] }}</div>
                    </div>

                    <div class="col-md-6 mt-3">
                        <label>Fecha</label>
                        <date-picker v-model="form.date" :disabled="!prop_edit" required="required"
                            valueType="format"></date-picker>
                        <div v-if="errors && errors.date" class="text-danger">{{ errors.date[0] }}</div>
                    </div>


                    <div class="col-md-6 mt-3">
                        <label>Tipo</label>
                        <select class="form-control" v-model="form.debts_type_id" @change="list()">
                            <option :value="type.id" v-for="(type, index) in catalogue.types_bill_number" :key="index">
                                {{ type.name }}</option>
                        </select>
                        <div v-if="errors && errors.debts_type_id" class="text-danger">{{ errors.debts_type_id[0] }}</div>
                    </div>



                    <div class="col-md-6 mt-3">
                        <label>Monto pagado</label>
                        <money :disabled="!prop_edit" class="form-control" v-model="form.amount"></money>
                        <div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}</div>
                    </div>

                    <!-- <div class="col-md-6 mt-3">
                        <label>Cuenta de banco</label>
                        <select v-model="form.bank_id" class="form-control">
                            <option value=""> Sin cuentas</option>
                            <option :value="bank.id" v-for="(bank, index) in catalogue.bank_account_company"
                                :key="index">
                                <span v-if="bank.bank">
                                    {{ bank.bank.name }}</span> - {{ bank.number }}
                            </option>
                        </select>
                        <div v-if="errors && errors.bank_id" class="text-danger">{{ errors.bank_id[0] }}</div>
                    </div> -->
                    <div class="col-md-12 mt-3">
                        <label>Descripción</label><br>
                        <textarea class="form-control" :disabled="!prop_edit" v-model="form.description"></textarea>
                    </div>

                    <div class="col-md-12 mt-3">
                        <label>Archivo</label><br>
                        <div class="custom-file" :style="file != '' ? 'width: 87%' : 'width: 100%'">
                            <input type="file" :disabled="!prop_edit" class="custom-file-input"
                                :class="file != '' ? 'pr-5' : ''" id="customFile" ref="file" @change="catch_file">
                            <label class="custom-file-label text-left" for="customFile">{{ file_name }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>

                    </div>



                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit" type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>

                </div>
            </div>
        </form>
    </modal>
</template>